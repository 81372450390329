
/* colors */
$standard-blue: #01589d;
$campus-yellow: #fdc700;
$white: #fff;


.yeardropdown select {
 background: #1779ba;
 background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>") !important;
 background-origin: content-box;
 background-position: right -1rem center;
 background-size: 9px 6px;
 background-repeat: no-repeat;
 border: none;
 color: #fff;
}

/* End select year */

/*Location address */
.location-address h4 small {
  color: #666 !important;
}

.profile-list .profile {
	padding: 1rem 1rem 2rem;
}

.profile .image {
	max-height: 275px;
}

.tags p {
	font-size: 1rem
}

a.whitelink,
a.whitelink:active {
	color: #fff;
	text-decoration: none;
}

a.whitelink:hover,
a.whitelink:focus {
	text-decoration: underline;
}

.sidebar-nav {
	list-style-type: none;
	margin-left: 0;
	padding-left: 0;
}

.sidebar-nav li {
	padding: 0 0 10px;
	margin: 0;
}

.sidebar-nav li .button {
	padding: 0;
	margin: 0;
}

.archive-container {
	padding: 1em; 
	background: #fff; 
	display: block; 
	margin: 1em 0;
}

.archive-year {
	padding-bottom: .5em; 
	border-bottom: 1px solid #ddd;
	margin-bottom: 1em;
}

.archive-year ul {
	list-style-type: none; 
	margin: 0; 
	padding: 0;
}

.archive-year ul li {
	display: inline-block; 
	padding: 0 10px 0 5px; 
	border-right: 1px solid #ddd;
}

.archive-year ul li:last-child {
	display: inline-block; 
	padding: 0 10px 0 5px; 
	border-right: 0px;
}

#search-results {
	margin-top: 3em;
	list-style-type: none;
}


#search-results li {
 background: $white;
 border-radius: .25em;
 display: inline-block;
 margin-bottom: 3em;
 padding: 1em;
 width: 100%;
}



/*End location address */

// Medium screens
@media only screen and (max-width: 40em) {


}


// Medium screens
@media only screen and (min-width: 40.063em) { 

 

} 
/* min-width 641px, medium screens */

// Large screens
@media only screen and (min-width: 64.063em) { 
 
 

} 
/* min-width 1025px, large screens */

// XLarge screens
@media only screen and (min-width: 90.063em) {
 


} 
/* min-width 1441px, xlarge screens */

// XXLarge screens
@media only screen and (min-width: 120.063em) {

} 
/* min-width 1921px, xxlarge screens */